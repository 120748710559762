<template>
    <router-link to="/">Home</router-link>
    <router-link to="/speakers">Speakers</router-link>
    <router-link to="/pastevents">Past Events</router-link>
    <router-link to="/partners">Partners</router-link>
    <router-link to="/about">About Us</router-link>   
</template>

<script>
export default {
    name: 'NavLinks',
}
</script>
<style>
    
</style>
